import ScrollMagic from 'scrollmagic';
import $ from 'jquery';

function stickyBtn() {
   if( $('#register-form-pin').length ){
      var controller = new ScrollMagic.Controller();

      var container = document.getElementById("scene-container");

      var containerHeight = container.offsetHeight;
      
      var formHeight = document.getElementById("register-form-item").offsetHeight;

      var combinedDuration = containerHeight - formHeight;


      // build scene
      var scene = new ScrollMagic.Scene({triggerElement: container, duration: combinedDuration})
      .setClassToggle("#register-form-pin", "active") // add class toggle
      .addTo(controller);
   }
}
$(window).on("resize", function() {
   stickyBtn();
});
stickyBtn();
