class DesktopMenu {
  constructor() {
    this.hoveredSection = null
    this.timeout = null
  }

  configure(MenuItems) {
   let menuIcon = document.querySelector('.hamburger');
    if (menuIcon) {
      menuIcon.onclick = (e) => {
        if (menuIcon.classList.contains('is-active')){
          menuIcon.classList.remove('is-active')
        } else{
          menuIcon.classList.add('is-active')
        }
      }
    }
    menuItems.forEach((element) => {
      let menuSection = document.querySelector(element.hash)
      if(menuSection) {
        element.onmouseenter = (e) => this.activateHoveredSection(e.target.hash)

        menuSection.onmouseenter = (e) => this.activateHoveredSection(element.hash)

        const deactivateTimer = () => {
          this.timeout = setTimeout(() => {
            this.deactivatePreviousElements()
          }, 500)
        }
        menuSection.onmouseleave = deactivateTimer
        element.onmouseleave = deactivateTimer
      }
    })
  }
  deactivatePreviousElements() {
    let previouslyActive = document.querySelectorAll('.menu-block.active')
    previouslyActive.forEach((previousElement) =>
      previousElement.classList.remove('active'),
    )
    this.hoveredSection = null
  }
  activateHoveredSection(hash) {
    let targetElement = document.querySelector(hash)
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    if (this.hoveredSection !== hash) {
      this.hoveredSection = hash
      this.deactivatePreviousElements()
      if (targetElement) {
        targetElement.classList.add('active')
      }
    }
  }
}

const Menu = new DesktopMenu()
const menuItems = document.querySelectorAll('.header-bar nav ul a[href^="#"')

Menu.configure(menuItems)
