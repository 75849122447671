import ScrollMagic from 'scrollmagic';
import $ from 'jquery';

$('.pipeline-section').each(function () {
    var controller = new ScrollMagic.Controller({
        globalSceneOptions: {
            triggerHook: 0.5
        }
    });
    var currentPipeline = $(this);
    var scene = new ScrollMagic.Scene({
        triggerElement: currentPipeline[0]
    })
    .setClassToggle(currentPipeline[0], "active") // add class toggle
    //.addIndicators()
    .reverse(false)
    .addTo(controller);
});