import 'bootstrap';
import './components/menu';
import './components/clinical-trial';
import './components/knowledge-hub';
import './components/register';
import './components/development-pipeline';
import './components/countdownTimer';
import './components/stickyBtn';
import Glide from '@glidejs/glide';


if (document.querySelector('.glide')) {
  new Glide('.glide').mount()
}
if (document.querySelector('.glide-3')) {
  new Glide('.glide-3', {
    type: 'carousel',
    perView: 3,
    focusAt: 'center'
  }).mount()
}
if (document.querySelector('[href="#update-cookie-consent"]')) {
  document.querySelector('[href="#update-cookie-consent"]').onclick = (e) => {
    e.preventDefault();
    if (document.querySelector('#elc-tab-link')) {
      document.querySelector('#elc-tab-link').click();
    }
  }

}


// Create a condition that targets viewports at least 768px wide
const mediaQuery = window.matchMedia('(min-width: 768px)')

let mainNavLinks = document.querySelectorAll(".in-page-nav ul li a");
let mainSections = document.querySelectorAll(".page-section");

let lastId;
let cur = [];
  
let ticking = false;
window.addEventListener("scroll", event => {
  if (!ticking) {
    window.requestAnimationFrame(function() {
      highlightActiveSection();
      ticking = false;
    });

    ticking = true;
  }
});

function highlightActiveSection() {
  let fromTop = window.scrollY;
  mainNavLinks.forEach(link => {
    link.classList.remove("active");
  });

  let inScreenSections = Array.prototype.slice.call(mainNavLinks).filter(link => {
    let section = document.querySelector(link.hash);
    return (
      section.offsetTop <= fromTop &&
      section.offsetTop + section.offsetHeight > fromTop
    )
  });
  let lastInscreenSection = inScreenSections[inScreenSections.length - 1];
  if(lastInscreenSection){
    lastInscreenSection.classList.add("active");
  }
}
function inPageNav() {
    let navHeight = document.querySelector('#fixed-header').offsetHeight + 'px';
    let element = 
    document.querySelector('.in-page-nav');
    if(element){
      element.style.top = navHeight;
    }
}

function handleTabletChange(e) {
  // Check if the media query is true
  if (e.matches) {
    const nav = document.querySelector('#fixed-header');
    const navTop = nav.offsetTop;

    function stickyNavigation() {
      // console.log('navTop = ' + navTop);
      // console.log('scrollY = ' + window.scrollY);
      if (window.scrollY >= navTop) {
        // nav offsetHeight = height of nav
        document.body.style.paddingTop = nav.offsetHeight + 'px';
        document.body.classList.add('fixed-nav');
      } else {
        document.body.style.paddingTop = 0;
        document.body.classList.remove('fixed-nav');
      }
    }
    
    window.addEventListener('scroll', stickyNavigation);
    inPageNav()
  }
  
}

// Register event listener
mediaQuery.addListener(handleTabletChange)

// Initial check
handleTabletChange(mediaQuery)
inPageNav()
