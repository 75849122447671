const findATrialBlock = document.querySelector('.find-a-trial');
if(findATrialBlock){
    const clinicalTrialStatusTriggers = document.querySelectorAll('.select-clinical-trial-status');
    const clinicalTrialLocationTriggers = document.querySelectorAll('.select-clinical-trial-location');
    let activeStatus='status-all';
    let activeLocation='location-all';
    const filterClinicalTrials = (status,location) => {
        document.querySelectorAll('.clinical-trial').forEach(clinicalTrial => {
            clinicalTrial.classList.add('hide')
        });
        document.querySelectorAll(`.clinical-trial.${status}.${location}`).forEach(clinicalTrial => {
            clinicalTrial.classList.remove('hide')
        });
    }
    const filterByStatus = (e) =>{
        e.preventDefault();
        activeStatus = e.target.dataset.status;
        document.querySelectorAll('.active .select-clinical-trial-status').forEach(element => element.parentElement.classList.remove('active'));
        e.target.parentElement.classList.add('active')
        filterClinicalTrials(activeStatus,activeLocation);
    }
    const filterByLocation = (e) =>{
        e.preventDefault();
        activeLocation = e.target.dataset.location;
        document.querySelectorAll('.active .select-clinical-trial-location').forEach(element => element.parentElement.classList.remove('active'));
        e.target.parentElement.classList.add('active')
        filterClinicalTrials(activeStatus,activeLocation);
    }
    clinicalTrialStatusTriggers.forEach(statusTrigger => statusTrigger.onclick = filterByStatus);
    clinicalTrialLocationTriggers.forEach(statusTrigger => statusTrigger.onclick = filterByLocation);

    if(window.imaginAb && window.imaginAb.preselectedLocation){
        let preselectedLocation = `location-${window.imaginAb.preselectedLocation}`;
        let elementToClick = document.querySelector(`.select-clinical-trial-location[data-location="${preselectedLocation}"]`);
        elementToClick.click();
        // filterClinicalTrials(activeStatus,activeLocation);
        // alert(window.imaginAb.preselectedLocation);
    }
}