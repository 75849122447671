import $ from 'jquery'
const submitMailchimp = (email, url, form) => {
  const XHR = new XMLHttpRequest()
  let urlEncodedData = '',
    urlEncodedDataPairs = [],
    name

  urlEncodedDataPairs.push(
    `${encodeURIComponent('EMAIL')}=${encodeURIComponent(email)}`,
  )
  urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+')

  $.ajax({
    url: url,
    type: 'GET',
    data: urlEncodedData,
    dataType: 'jsonp',
    contentType: 'application/json; charset=utf-8',
    success: function (data) {
      if (data['result'] != 'success') {
        //ERROR
      } else {
      }
      form.submit()
    },
  })
}
window.addEventListener('DOMContentLoaded', (event) => {
  let element = document.querySelector('.register-form form');
  let form = document.getElementById('mailchimp-jsonp');
  let email = document.getElementById('email');
  if (element && form && email) {
    element.onsubmit = (e) => {
        console.log('submit stop');
      e.preventDefault();
      let enrol = document.getElementById('up-to-date')
      if (enrol.checked) {
          console.log(form.action,e.target);
        submitMailchimp(
          email.value,
          form.action,
          e.target,
        )
      } else {
        e.target.submit()
      }
    }
  }
})
