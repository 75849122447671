const knowledgeHubBlock = document.querySelector('.knowledge-hub .categories');
if(knowledgeHubBlock){
    const knowledgeHubCategoryTriggers = document.querySelectorAll('.knowledge-hub-category a');

    let activeCategory = 'category-all';
    const filterKnowledgeHubItems = (category) => {
        document.querySelectorAll('.knowledge-hub-item').forEach(knowledgeHubItem => {
            knowledgeHubItem.classList.add('hide');
        });
        document.querySelectorAll(`.knowledge-hub-item.${category}`).forEach(knowledgeHubItem => {
          ;  knowledgeHubItem.classList.remove('hide');
        })
    }  

    const categoryClickHandler = (e) => {
        e.preventDefault();
        activeCategory = e.target.parentElement.dataset.category;
        document.querySelectorAll('.knowledge-hub-category.active').forEach(element => element.classList.remove('active'));
        e.target.parentElement.classList.add('active')
        filterKnowledgeHubItems(activeCategory);
    }
    knowledgeHubCategoryTriggers.forEach(categoryTrigger => categoryTrigger.onclick = categoryClickHandler);

}