// from https://www.w3schools.com/howto/howto_js_countdown.asp
import moment from 'moment';
import tz from 'moment-timezone';

const countdownTimer = document.getElementById("timer");


if (countdownTimer) {

// Set the date we're counting down to
var countDownDate = new Date(countdownTimer.getAttribute('data-date')).getTime();
// Update the count down every 1 second
var x = setInterval(function() {
  // Get today's date and time
  //var now = new Date().getTime();
  
  var pt = moment().tz("America/New_York").format('ddd MMM DD YYYY HH:mm:ss');

  var now = new Date(pt).getTime();

  // Find the distance between now and the count down date
  var distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  // Display the result in the element with id="demo"
  document.getElementById("timer").innerHTML = "<div class=\"col-3 h2 time\">" + days + "<span class=\"h5\">dd</span>" + "</div>" + "<div class=\"col-3 h2 time\">" + hours + "<span class=\"h5\">hh</span>" + "</div>"
  + "<div class=\"col-3 h2 time\">" + minutes + "<span class=\"h5\">mm</span>" + "</div>" + "<div class=\"col-3 h2 time\">" + seconds + "<span class=\"h5\">ss</span>" + "</div>";

  // If the count down is finished, write some text
  if (distance < 0) {
    clearInterval(x);
    document.getElementById("timer").innerHTML = "<div class=\"live-now\"><h2>Live Now</h2></div>";
  }
}, 1000);
}